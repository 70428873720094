import { Subject } from "../../published/Subject";
import { SubjectValue } from "../SubjectValue";
import { SiteMeta } from "../../published/sites/SiteMeta";
import { SubjectUtils } from "../SubjectUtils";
import { Site } from "./Site";
import { ExpressionEngine } from "../../../expressions/ExpressionEngine";
import * as moment from "moment";

export class SiteUtils extends SubjectUtils
{
    /**
     * This function gets the displayable label for the site from the field value as 
     * defined within the meta data.
     * 
     * NOTE: THIS CAN BE DONE BETTER, AND SHOULD BE, ONE DAY!
     * 
     * @param type
     * @param site 
     * @returns 
     */
    getSiteLabel ( engine: ExpressionEngine, type: SiteMeta, site: Site ) : string
    {
        return this.getSubjectLabel ( engine, type, site, null );
    }

    getFormattedId ( type: Subject, value: SubjectValue | null ) : string | null
    {
        if ( type instanceof SiteMeta && value instanceof Site )
        {
            return this.getSiteFormattedId ( type, value );
        }

        return null;
    }

    getSiteFormattedId ( type: SiteMeta, site: Site | null ) : string | null
    {
        if ( site && site.SiteId && site.SiteId.Id )
        {
            if ( type.IdDigits )
            {
                return String ( site.SiteId.Id ).padStart ( type.IdDigits, "0" );
            }

            return site.SiteId.Id.toString ( );
        }

        return null;
    }

    getSubjectVariableValues ( type: SiteMeta, value: Site,
                               prefix: string ) : Map<string, boolean | number | string | moment.Moment | null>
    {
        const vals = super.getSubjectVariableValues ( type, value, prefix );
        vals.set ( `${prefix}.___.allocation`, value.Allocation );
        return vals
    }
}