import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { combineLatest, from, Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { Role } from "../model/published/Role";
import { Section } from "../model/published/Section"
import { Subject } from "../model/published/Subject"
import { Form } from "../model/published/Form"
import { SiteMeta } from "../model/published/sites/SiteMeta";

@Injectable ( {
    providedIn: 'root'
} )
export class CurrentUserUtilsService
{
    private name: string | null = null;

    private email: string | null = null;

    private username: string | null = null;

    constructor ( private keycloak: KeycloakService )
    {
      keycloak.isLoggedIn ( ).then ( loggedIn => {
        if ( loggedIn )
        {
          keycloak.loadUserProfile ( ).then ( profile => {
            this.name = `${profile.firstName} ${profile.lastName}`;
            this.email = profile.email ? profile.email : null;
            this.username = keycloak.getUsername ( );
          } );
        }
      } );
    }

    getName ( ) : string | null
    {
      return this.name;
    }

    getEmail ( ) : string | null
    {
      return this.email;
    }

    getUsername ( ) : string | null
    {
      return this.username;
    }

    hasSysAdminRole ( ) : Observable<boolean>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
          map ( loggedIn => {
            if ( loggedIn )
            {
              const roles = this.keycloak.getUserRoles ( true );
              return roles.includes ( "sys_admin" );
            }
            else
            {
              return false;
            }
          } ),
          first ( )
        );
    }

    hasSysUserRole ( ) : Observable<boolean>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
          map ( loggedIn => {
            if ( loggedIn )
            {
              const roles = this.keycloak.getUserRoles ( true );
              return roles.includes ( "sys_user" );
            }
            else
            {
              return false;
            }
          } ),
          first ( )
        );
    }

    hasOrgAdminRole ( ) : Observable<boolean>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
          map ( loggedIn => {
            if ( loggedIn )
            {
              const roles = this.keycloak.getUserRoles ( true );
              return roles.includes ( "org_admin" );
            }
            else
            {
              return false;
            }
          } ),
          first ( )
        );
    }

    hasOrgUserRole ( ) : Observable<boolean>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
          map ( loggedIn => {
            if ( loggedIn )
            {
              const roles = this.keycloak.getUserRoles ( true );
              return roles.includes ( "org_user" );
            }
            else
            {
              return false;
            }
          } ),
          first ( )
        );
    }

    hasUserManagementRole ( ) : Observable<boolean>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
          map ( loggedIn => {
            if ( loggedIn )
            {
              const roles = this.keycloak.getUserRoles ( true );
              return roles.includes ( "sys_admin" ) || roles.includes ( "org_admin" );
            }
            else
            {
              return false;
            }
          } ),
          first ( )
        );
    }

    hasDataExportRole ( ) : Observable<boolean>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
          map ( loggedIn => {
            if ( loggedIn )
            {
              const roles = this.keycloak.getUserRoles ( true );
              return roles.includes ( "sys_admin" ) || roles.includes ( "sys_user" ) || roles.includes ( "org_admin" ) || roles.includes ( "org_user" );
            }
            else
            {
              return false;
            }
          } ),
          first ( )
        );
    }

    hasVariableViewRole ( ) : Observable<boolean>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
          map ( loggedIn => {
            if ( loggedIn )
            {
              const roles = this.keycloak.getUserRoles ( true );
              return roles.includes ( "sys_admin" ) || roles.includes ( "sys_user" ) || roles.includes ( "org_admin" ) || roles.includes ( "org_user" );
            }
            else
            {
              return false;
            }
          } ),
          first ( )
        );
    }

    getUserRoles ( ) : Observable<Array<string>>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
        map ( loggedIn => {
          if ( loggedIn )
          {
            const roles = this.keycloak.getUserRoles ( true );
            return roles;
          }
          else
          {
            return [];
          }
        } ),
        first ( )
      );
    }

    hasRole ( requiredRoles: Array<Role>, userRoles: Array<string> ) : boolean
    {
      const requiredRoleNames = requiredRoles.filter ( role => role.Name ).map ( role => role.Name ? role.Name : "" );
      return requiredRoleNames.some ( required => userRoles.indexOf ( required ) >= 0 );
    }

    hasRoleLazy ( requiredRoles: Array<Role> ) : Observable<boolean>
    {
      return from ( this.keycloak.isLoggedIn ( ) ).pipe (
        map ( loggedIn => {
          if ( loggedIn )
          {
            const roles = this.keycloak.getUserRoles ( true );
            return this.hasRole ( requiredRoles, roles );
          }
          else
          {
            return false;
          }
        } ),
        first ( )
      );
    }

    hasSiteCreateAccess ( site: SiteMeta, userRoles: Array<string> ) : boolean
    {
      return this.hasRole ( Array.from ( site.CreateACL ? site.CreateACL.values ( ) : [] ), userRoles )
    }

    protected hasSectionAccess ( section: Section, aclGetter: (section: Section) => Map<string, Role> | null, userRoles: Array<string> ) : boolean
    {
      const acl = aclGetter ( section );
      if ( acl )
      {
          if ( this.hasRole ( Array.from ( acl.values ( ) ), userRoles ) )
          {
              return true;
          }
      }
      else
      {
          return true;
      }

      return false;
    }

    hasSectionViewAccess ( section: Section, userRoles: Array<string> ) : boolean
    {
      return this.hasSectionAccess ( section, ( section: Section ) : Map<string, Role> | null => section.ViewACL, userRoles );
    }

    hasSectionEditAccess ( section: Section, userRoles: Array<string> ) : boolean
    {
      return this.hasSectionAccess ( section, ( section: Section ) : Map<string, Role> | null => section.EditACL, userRoles );
    }

    hasSectionCompleteEditAccess ( section: Section, userRoles: Array<string> ) : boolean
    {
      return this.hasSectionAccess ( section, ( section: Section ) : Map<string, Role> | null => section.CompleteEditACL, userRoles );
    }

    protected hasFormAccess ( form: Form, aclGetter: (section: Section) => Map<string, Role> | null, userRoles: Array<string> ) : boolean
    {
      for ( const section of form.Sections ? form.Sections : [] )
      {
          if ( this.hasSectionAccess ( section, aclGetter, userRoles ) )
          {
            return true;
          }
      }

      return false;
    }

    hasFormViewAccess ( form: Form, userRoles: Array<string> ) : boolean
    {
      return this.hasFormAccess ( form, ( section: Section ) : Map<string, Role> | null => section.ViewACL, userRoles );
    }

    hasFormEditAccess ( form: Form, userRoles: Array<string> ) : boolean
    {
      return this.hasFormAccess ( form, ( section: Section ) : Map<string, Role> | null => section.EditACL, userRoles );
    }

    hasFormCompleteEditAccess ( form: Form, userRoles: Array<string> ) : boolean
    {
      return this.hasFormAccess ( form, ( section: Section ) : Map<string, Role> | null => section.CompleteEditACL, userRoles );
    }

    protected hasSubjectFormAccess ( subject: Subject, aclGetter: (section: Section) => Map<string, Role> | null, userRoles: Array<string> ) : boolean
    {
        for ( const cp of subject.AllCollectionPoints )
        {
            for ( const form of cp.Forms ? cp.Forms : [] )
            {
              if ( this.hasFormAccess ( form, aclGetter, userRoles ) )
              {
                return true;
              }
            }
        }

        return false;
    }

    hasSubjectFormViewAccess ( subject: Subject, userRoles: Array<string> ) : boolean
    {
      return this.hasSubjectFormAccess ( subject, ( section: Section ) : Map<string, Role> | null => section.ViewACL, userRoles );
    }

    hasSubjectFormEditAccess ( subject: Subject, userRoles: Array<string> ) : boolean
    {
      return this.hasSubjectFormAccess ( subject, ( section: Section ) : Map<string, Role> | null => section.EditACL, userRoles );
    }

    hasSubjectFormCompleteEditAccess ( subject: Subject, userRoles: Array<string> ) : boolean
    {
      return this.hasSubjectFormAccess ( subject, ( section: Section ) : Map<string, Role> | null => section.CompleteEditACL, userRoles );
    }

    hasSubjectFormsViewAccess ( subjects: Array<Subject>, userRoles: Array<string> ) : boolean
    {
        for ( const subject of subjects )
        {
            if ( this.hasSubjectFormViewAccess ( subject, userRoles ) )
            {
                return true;
            }
        }

        return false;
    }

    hasSubjectFormsEditAccess ( subjects: Array<Subject>, userRoles: Array<string> ) : boolean
    {
        for ( const subject of subjects )
        {
            if ( this.hasSubjectFormEditAccess ( subject, userRoles ) )
            {
                return true;
            }
        }

        return false;
    }

    hasSubjectFormsCompleteEditAccess ( subjects: Array<Subject>, userRoles: Array<string> ) : boolean
    {
        for ( const subject of subjects )
        {
            if ( this.hasSubjectFormCompleteEditAccess ( subject, userRoles ) )
            {
                return true;
            }
        }

        return false;
    }

    userRequiresTaskRefresh ( ) : Observable<boolean>
    {
        return combineLatest ( [ this.hasOrgAdminRole ( ),
                                 this.hasOrgUserRole ( ),
                                 this.hasSysAdminRole ( ),
                                 this.hasSysUserRole ( ) ] ).pipe (
            map ( ( [ hasOrgAdminRole, hasOrgUserRole, hasSysAdminRole, hasSysUserRole ] ) => 
                  !(hasOrgAdminRole || hasOrgUserRole || hasSysAdminRole || hasSysUserRole) )
        );
    }
}