import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigService } from './config/config.service';
import { ERRORS_FEATURE_STATE_NAME, ERRORS_REDUCER } from './errors/errors.reducers';
import { ErrorsService } from './errors/errors.service';
import { SectionToolbarDirective } from './layout/section.toolbar.directive';
import { FormLibraryService } from './services/meta/form.library.service';
import { PublishedSitesService } from './services/published/published-sites.service';
import { SharedComponent } from './shared.component';
import { FormLibraryEffects } from './state/meta/form.library.effects';
import { FORM_LIBRARY_REDUCER } from './state/meta/form.library.reducers';
import { FORM_LIBRARY_FEATURE_STATE_NAME } from './state/meta/form.library.state';
import { PUBLISHED_SITES_REDUCER } from './state/published/sites/published-sites.reducers';
import { PUBLISHED_SITES_FEATURE_STATE_NAME } from './state/published/sites/published-sites.state';
import { PublishedSitesEffects } from './state/published/sites/published-sites.effects';
import { PUBLISHED_PAGES_FEATURE_STATE_NAME } from './state/published/pages/published-pages.state';
import { PUBLISHED_PAGES_REDUCER } from './state/published/pages/published-pages.reducers';
import { PublishedPagesEffects } from './state/published/pages/published-pages.effects';
import { STUDY_SITES_FEATURE_STATE_NAME } from './state/study/sites/study-sites.state';
import { STUDY_SITES_REDUCER } from './state/study/sites/study-sites.reducers';
import { StudySitesEffects } from './state/study/sites/study-sites.effects';
import { StudySitesService } from './services/study/study-sites.service';
import { SYSTEM_USERS_FEATURE_STATE_NAME } from './state/system/users/system-users.state';
import { SYSTEM_USERS_REDUCER } from './state/system/users/system-users.reducers';
import { SystemUsersEffects } from './state/system/users/system-users.effects';
import { SystemUsersService } from './services/system/system-users.service';
import { EnumToArrayPipe } from './utils/enum.to.array.pipe';
import { SYSTEM_GROUPS_FEATURE_STATE_NAME } from './state/system/groups/system-groups.state';
import { SYSTEM_GROUPS_REDUCER } from './state/system/groups/system-groups.reducers';
import { SystemGroupsEffects } from './state/system/groups/system-groups.effects';
import { SystemGroupsService } from './services/system/system-groups.service';
import { SYSTEM_ROLES_REDUCER } from './state/system/roles/system-roles.reducers';
import { SYSTEM_ROLES_FEATURE_STATE_NAME } from './state/system/roles/system-roles.state';
import { SystemRolesEffects } from './state/system/roles/system-roles.effects';
import { SystemRolesService } from './services/system/system-roles.service';
import { PUBLISHED_PARTICIPANTS_FEATURE_STATE_NAME } from './state/published/participants/published-participants.state';
import { PUBLISHED_PARTICIPANTS_REDUCER } from './state/published/participants/published-participants.reducers';
import { PublishedParticipantsEffects } from './state/published/participants/published-participants.effects';
import { PublishedParticipantsService } from './services/published/published-participants.service';
import { STUDY_PARTICIPANTS_FEATURE_STATE_NAME } from './state/study/participants/study-participants.state';
import { STUDY_PARTICIPANTS_REDUCER } from './state/study/participants/study-participants.reducers';
import { StudyParticipantsEffects } from './state/study/participants/study-participants.effects';
import { StudyParticipantsService } from './services/study/study-participants.service';
import { ExpressionEngineFactory } from './expressions/ExpressionEngineFactory';
import { STUDY_TASKS_FEATURE_STATE_NAME } from './state/study/tasks/study-tasks.state';
import { STUDY_TASKS_REDUCER } from './state/study/tasks/study-tasks.reducers';
import { StudyTasksEffects } from './state/study/tasks/study-tasks.effects';
import { StudyTasksService } from './services/study/study-tasks.service';
import { StudyParticipantsFascadeService } from './services/study-participants-fascade-service';
import { StudySitesFascadeService } from './services/study-sites-fascade-service';
import { CurrentUserUtilsService } from './services/current.user.utils.service';
import { DirectAccessGuard } from './utils/direct.access.guard';
import { EXPORT_FEATURE_STATE_NAME } from './state/export/export.state';
import { EXPORT_REDUCER } from './state/export/export.reducers';
import { ExportEffects } from './state/export/export.effects';
import { ExportService } from './services/export/export.service';
import { STUDY_DOCUMENTS_FEATURE_STATE_NAME } from './state/study/documents/study-documents.state';
import { STUDY_DOCUMENTS_REDUCER } from './state/study/documents/study-documents.reducers';
import { StudyDocumentsEffects } from './state/study/documents/study-documents.effects';
import { StudyDocumentsService } from './services/study/study-documents.service';


@NgModule({
  declarations: [SharedComponent, SectionToolbarDirective, EnumToArrayPipe],
  imports: [
    HttpClientModule,
    StoreModule.forFeature ( ERRORS_FEATURE_STATE_NAME, ERRORS_REDUCER ),
    StoreModule.forFeature ( SYSTEM_GROUPS_FEATURE_STATE_NAME, SYSTEM_GROUPS_REDUCER ),
    StoreModule.forFeature ( SYSTEM_ROLES_FEATURE_STATE_NAME, SYSTEM_ROLES_REDUCER ),
    StoreModule.forFeature ( SYSTEM_USERS_FEATURE_STATE_NAME, SYSTEM_USERS_REDUCER ),
    StoreModule.forFeature ( FORM_LIBRARY_FEATURE_STATE_NAME, FORM_LIBRARY_REDUCER ),
    StoreModule.forFeature ( PUBLISHED_PAGES_FEATURE_STATE_NAME, PUBLISHED_PAGES_REDUCER ),
    StoreModule.forFeature ( PUBLISHED_SITES_FEATURE_STATE_NAME, PUBLISHED_SITES_REDUCER ),
    StoreModule.forFeature ( PUBLISHED_PARTICIPANTS_FEATURE_STATE_NAME, PUBLISHED_PARTICIPANTS_REDUCER ),
    StoreModule.forFeature ( STUDY_SITES_FEATURE_STATE_NAME, STUDY_SITES_REDUCER ),
    StoreModule.forFeature ( STUDY_PARTICIPANTS_FEATURE_STATE_NAME, STUDY_PARTICIPANTS_REDUCER ),
    StoreModule.forFeature ( STUDY_TASKS_FEATURE_STATE_NAME, STUDY_TASKS_REDUCER ),
    StoreModule.forFeature ( STUDY_DOCUMENTS_FEATURE_STATE_NAME, STUDY_DOCUMENTS_REDUCER ),
    StoreModule.forFeature ( EXPORT_FEATURE_STATE_NAME, EXPORT_REDUCER ),
    EffectsModule.forFeature ( [ SystemUsersEffects, SystemGroupsEffects, SystemRolesEffects, FormLibraryEffects,
                                 PublishedPagesEffects, PublishedSitesEffects, PublishedParticipantsEffects,
                                 StudySitesEffects, StudyParticipantsEffects, StudyTasksEffects, StudyDocumentsEffects, ExportEffects ] ),
  ],
  providers: [ ConfigService, ErrorsService, SystemGroupsService, SystemRolesService, SystemUsersService, FormLibraryService, StudyDocumentsService,
               PublishedSitesService, PublishedParticipantsService, StudySitesService, StudyParticipantsService, StudyTasksService, ExportService,
               ExpressionEngineFactory, StudyParticipantsFascadeService, StudySitesFascadeService, CurrentUserUtilsService, DirectAccessGuard ],
  exports: [SharedComponent, SectionToolbarDirective, EnumToArrayPipe]
})
export class SharedModule
{

  // constructor( @Optional() @SkipSelf() parentModule?: SharedModule )
  // {
  //   if ( parentModule )
  //   {
  //     throw new Error(
  //       'SharedModule is already loaded. Import it in the AppModule only');
  //   }
  // }

}
