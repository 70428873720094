import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionDetailsComponent } from './ui/section.details.component'
import { ReactiveFormsModule } from '@angular/forms';
import { FieldsModule, SharedModule } from 'shared';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MandatoryLoginAuthGuard } from './MandatoryLoginAuthGuard';
import { LoadingComponent } from './ui/loading.component';
import { ExportingComponent } from './ui/exporting.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    FieldsModule
  ],
  declarations: [ SectionDetailsComponent, LoadingComponent, ExportingComponent ],
  providers: [ MandatoryLoginAuthGuard ],
  exports: [ SectionDetailsComponent, LoadingComponent, ExportingComponent ]
})
export class ManagementSharedModule { }
