import { Expression } from '../published/expressions/Expression';
import { Role } from './Role';
import { Section } from './Section';

export class Form
{
    public static clone ( input: Form ) : Form
    {
        const output = new Form ( );
        output.Id = input.Id;
        output.Version = input.Version;
        output.Seq = input.Seq;
        output.Variable = input.Variable;
        output.Name = input.Name;
        output.Description = input.Description;
        output.QuestionNumbers = input.QuestionNumbers;
        output.LabelFieldName = input.LabelFieldName;
        output.Sections = new Array<Section> ( );
        for ( const section of input.Sections ? input.Sections: [] )
        {
            output.Sections.push ( Section.clone ( section ) );
        }
        output.ExcludeExpression = input.ExcludeExpression ? Expression.clone ( input.ExcludeExpression ) : null;
        output.DisabledExpression = input.DisabledExpression ? Expression.clone ( input.DisabledExpression ) : null;
        output.StatusValueExpression = input.StatusValueExpression ? Expression.clone ( input.StatusValueExpression ) : null;
        output.StatusMessageExpression = input.StatusMessageExpression ? Expression.clone ( input.StatusMessageExpression ) : null;
        return output;
    }

    public static toJson ( form: Form ): any
    {
        let out: any = {};

        out.id = form.Id;
        out.version = form.Version;
        out.seq = form.Seq;
        out.variable = form.Variable;
        out.name = form.Name;
        out.description = form.Description;
        out.question_numbers = form.QuestionNumbers;
        out.label_field_name = form.LabelFieldName;
        out.sections = new Array<any> ( );
        for ( const section of form.Sections ? form.Sections : [] )
        {
            out.sections.push ( Section.toJson ( section ) );
        }
        out.exclude_expression = form.ExcludeExpression ? Expression.toJson ( form.ExcludeExpression ) : null;
        out.disabled_expression = form.DisabledExpression ? Expression.toJson ( form.DisabledExpression ) : null;
        out.status_value_expression = form.StatusValueExpression ? Expression.toJson ( form.StatusValueExpression ) : null;
        out.status_message_expression = form.StatusMessageExpression ? Expression.toJson ( form.StatusMessageExpression ) : null;

        return out;
    }

    public static fromJson( json: any ): Form
    {
        let out = new Form ( );

        out.Id = json.id;
        out.Version = json.version;
        out.Seq = json.seq;
        out.Variable = json.variable;
        out.Name = json.name;
        out.Description = json.description;
        out.QuestionNumbers = json.question_numbers;
        out.LabelFieldName = json.label_field_name;
        out.Sections = new Array<Section> ( );
        for ( const section of json.sections ? json.sections : [] )
        {
            const parsed = Section.fromJson ( section );
            if ( parsed != null )
            {
                out.Sections.push ( parsed );
            }
        }
        out.ExcludeExpression = "exclude_expression" in json && json.exclude_expression ? Expression.fromJson ( json.exclude_expression ) : null;
        out.DisabledExpression = "disabled_expression" in json && json.disabled_expression ? Expression.fromJson ( json.disabled_expression ) : null;
        out.StatusValueExpression = "status_value_expression" in json && json.status_value_expression ? Expression.fromJson ( json.status_value_expression ) : null;
        out.StatusMessageExpression = "status_message_expression" in json && json.status_message_expression ? Expression.fromJson ( json.status_message_expression ) : null;

        return out;
    }

    public constructor( private id: number | null  = null,
                        private version: number | null  = null, 
                        private seq: number | null = null,
                        private variable: string | null  = null, 
                        private name: string | null  = null, 
                        private description: string | null = null,
                        private questionNumbers: boolean | null = null,
                        private labelFieldName: string | null = null,
                        private sections: Array<Section> | null  = null,
                        private excludeExpression: Expression | null = null,
                        private disabledExpression: Expression | null = null,
                        private statusValueExpression: Expression | null = null,
                        private statusMessageExpression: Expression | null = null, )
    {
       // Null.
    }

    public get Id( ): number | null
    {
        return this.id;
    }

    public set Id( id: number | null )
    {
        this.id = id;
    }

    public get Version( ): number | null
    {
        return this.version;
    }

    public set Version( version: number | null )
    {
        this.version = version;
    }

    public get Seq( ): number | null
    {
        return this.seq;
    }

    public set Seq( seq: number | null )
    {
        this.seq = seq;
    }

    public get Variable( ): string | null
    {
        return this.variable;
    }

    public set Variable( variable: string | null )
    {
        this.variable = variable;
    }

    public get Name( ): string | null
    {
        return this.name;
    }

    public set Name( name: string | null )
    {
        this.name = name;
    }

    public get Description( ): string | null
    {
        return this.description;
    }

    public set Description( description: string | null )
    {
        this.description = description;
    }

    public get QuestionNumbers( ): boolean | null
    {
        return this.questionNumbers;
    }

    public set QuestionNumbers( questionNumbers: boolean | null )
    {
        this.questionNumbers = questionNumbers;
    }

    public get LabelFieldName( ): string | null
    {
        return this.labelFieldName;
    }

    public set LabelFieldName( labelFieldName: string | null )
    {
        this.labelFieldName = labelFieldName;
    }
    
    public get Sections( ): Array<Section> | null
    {
        return this.sections;
    }

    public set Sections( sections: Array<Section> | null )
    {
        this.sections = sections;
    }

    public get ExcludeExpression( ): Expression | null
    {
        return this.excludeExpression;
    }

    public set ExcludeExpression( expression: Expression | null )
    {
        this.excludeExpression = expression;
    }

    public get DisabledExpression( ): Expression | null
    {
        return this.disabledExpression;
    }

    public set DisabledExpression( expression: Expression | null )
    {
        this.disabledExpression = expression;
    }

    public get StatusValueExpression( ): Expression | null
    {
        return this.statusValueExpression;
    }

    public set StatusValueExpression( expression: Expression | null )
    {
        this.statusValueExpression = expression;
    }

    public get StatusMessageExpression( ): Expression | null
    {
        return this.statusMessageExpression;
    }

    public set StatusMessageExpression( expression: Expression | null )
    {
        this.statusMessageExpression = expression;
    }
}
